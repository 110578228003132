.experience-section {
  position: relative;
  padding: 4rem 2rem;
  background-color: var(--color-dark);
  overflow: hidden;
}

/* Optional background glow */
.experience-section::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle at center, var(--color-accent) 10%, transparent 60%);
  opacity: 0.05;
  transform: rotate(25deg);
  z-index: 0;
}

.experience-section h1 {
  font-family: 'Orbitron', sans-serif;
  color: var(--color-accent);
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
  z-index: 2; /* Ensure it is above the background glow */
}

/* GRID CONTAINER */
.experience-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjusted for smaller cards */
  gap: 1.5rem;
  position: relative;
  z-index: 2;
}

/* JOB CARD */
.job-card {
  background-color: #2a2a2a;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

/* Hover tilt + glow */
.job-card:hover {
  transform: translateY(-5px) rotateX(3deg) rotateY(-3deg);
  box-shadow: 0 0 25px var(--color-accent);
}

/* Flicker effect background */
.job-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle at center, var(--color-secondary) 10%, transparent 70%);
  opacity: 0.03;
  z-index: 0;
  transition: opacity 0.3s;
}
.job-card:hover::before {
  opacity: 0.08;
}

/* CARD HEADER */
.card-header {
  position: relative;
  z-index: 2;
  margin-bottom: 1rem;
}

.job-icon {
  font-size: 1.8rem;
  color: var(--color-accent);
  margin-right: 0.5rem;
  vertical-align: middle;
}

/* Job Dates */
.job-dates {
  font-size: 0.75rem;
  display: block;
  color: var(--color-highlight);
  margin-top: 0.25rem;
}

/* Title in card header */
.job-card h2 {
  font-family: 'Orbitron', sans-serif;
  color: var(--color-secondary);
  margin: 0;
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
}

/* CARD BODY */
.card-body {
  position: relative;
  z-index: 2;
}

.card-body ul {
  list-style-type: disc;
  margin-left: 1rem;
  line-height: 1.4;
}

.card-body li {
  margin-bottom: 0.5rem;
}

/* Links */
.job-card a {
  color: var(--color-accent);
  text-decoration: none;
  transition: color 0.2s;
}
.job-card a:hover {
  text-decoration: underline;
}

/* RESPONSIVE ADJUSTMENTS */
@media (max-width: 1200px) {
  .experience-grid {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }
}

@media (max-width: 768px) {
  .experience-grid {
    grid-template-columns: 1fr;
  }

  .job-card {
    margin: 0 auto;
    width: 90%;
  }
}
