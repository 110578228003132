.education-section {
    padding: 4rem 2rem;
    background-color: var(--color-dark);
    text-align: center;
  }
  
  .education-section h1 {
    font-family: 'Orbitron', sans-serif;
    color: var(--color-accent);
    margin-bottom: 2rem;
  }
  
  .edu-details h2, .edu-details h3 {
    color: var(--color-highlight);
    margin-bottom: 0.5rem;
  }
  