.contact-section {
  position: relative;
  padding: 4rem 2rem;
  background-color: var(--color-dark);
  overflow: hidden;
  text-align: center;
}

/* --- RADIAL GLOW BACKGROUND (optional) --- */
.contact-section::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle at center,
    var(--color-accent) 10%,
    transparent 60%
  );
  opacity: 0.05;
  transform: rotate(25deg);
  z-index: 0;
}

/* --- MAIN HEADING --- */
.contact-section h1 {
  font-family: 'Orbitron', sans-serif;
  font-size: 2.5rem;
  color: var(--color-accent);
  margin-bottom: 3rem;
  position: relative;
  z-index: 2;
  animation: headingGlow 3s ease-in-out infinite alternate; /* optional glow */
}

@keyframes headingGlow {
  0% {
    text-shadow: 0 0 5px var(--color-accent);
  }
  100% {
    text-shadow: 0 0 20px var(--color-accent);
  }
}

/* --- CONTACT CONTAINER (info + form) --- */
.contact-container {
  position: relative;
  z-index: 2;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap; /* so it wraps on smaller screens */
  background-color: #2a2a2a;
  padding: 2rem;
  border: 2px solid var(--color-accent);
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 255, 204, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.contact-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 30px var(--color-accent);
}

/* --- INFO BLOCK (left side) --- */
.info-block {
  flex: 1 1 300px;
  text-align: left;
}

.info-block p {
  color: var(--color-highlight);
  margin: 1rem 0;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

/* Icons in the text */
.info-icon {
  font-size: 1.2rem;
  color: var(--color-accent);
  margin-right: 0.5rem;
}

/* Link styling in info block */
.info-block a {
  color: var(--color-accent);
  text-decoration: none;
  transition: color 0.3s;
  position: relative;
}

/* Hover line effect for links */
.info-block a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0%;
  height: 2px;
  background-color: var(--color-accent);
  transition: width 0.3s;
}
.info-block a:hover {
  color: var(--color-secondary);
}
.info-block a:hover::after {
  width: 100%;
}

/* --- CONTACT FORM (right side) --- */
.contact-form {
  flex: 1 1 300px;
  text-align: left;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  color: var(--color-accent);
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  background-color: #333;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  color: var(--color-highlight);
  outline: none;
  transition: border-color 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: var(--color-accent);
}

/* Submit button */
.submit-btn {
  background-color: var(--color-accent);
  color: var(--color-dark);
  font-family: 'Orbitron', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.submit-btn:hover {
  background-color: var(--color-secondary);
  box-shadow: 0 0 10px var(--color-secondary);
}

/* --- RESPONSIVE ADJUSTMENTS --- */
@media (max-width: 768px) {
  .contact-section {
    padding: 2rem 1rem;
  }

  .contact-section h1 {
    font-size: 2rem;
  }

  .contact-container {
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
  }

  .info-block {
    margin-bottom: 2rem;
    text-align: center; /* center text for small screens */
  }

  .info-block p {
    justify-content: center;
  }
}
