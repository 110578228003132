.skills-section {
  position: relative;
  padding: 4rem 2rem;
  background-color: var(--color-dark);
  text-align: center;
}

/* Main Title */
.skills-section h1 {
  font-family: 'Orbitron', sans-serif;
  color: var(--color-accent);
  margin-bottom: 3rem;
  font-size: 2.5rem;
}

/* FORCE exactly 5 columns in one row */
/* Add horizontal scroll if the screen is too narrow */
.skills-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 columns, 1 fraction each */
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; 
  padding: 0 1rem;
  overflow-x: auto; /* allows horizontal scroll if content doesn't fit */
}

/* Each skill card */
.skill-card {
  background-color: #2a2a2a;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 2rem 1.5rem;
  box-shadow: 0 0 15px rgba(0,0,0,0.5);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-width: 200px; /* ensures each card has a minimum width */
}

/* Hover effect */
.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 25px var(--color-accent);
}

/* Icon on top of the card */
.skill-icon {
  font-size: 2.5rem;
  color: var(--color-accent);
  margin-bottom: 1rem;
}

/* Title of the skill card */
.skill-info h2 {
  font-family: 'Orbitron', sans-serif;
  color: var(--color-secondary);
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
}

/* Text / Description of the skill */
.skill-info p {
  color: var(--color-highlight);
  font-size: 1rem;
  line-height: 1.5;
}

/* 
   If you really want them to remain 5-up on mobile too,
   you can remove or adjust the media query altogether.
   But here's an example if you want smaller fonts/padding on mobile:
*/
@media (max-width: 768px) {
  .skills-section {
    padding: 2rem 1rem;
  }

  .skills-section h1 {
    font-size: 2rem;
  }

  .skill-card {
    padding: 1.5rem 1rem;
  }

  .skill-info h2 {
    font-size: 1.1rem;
  }
}
