.projects-section {
  padding: 4rem 2rem;
  background-color: var(--color-dark);
  text-align: center;
}

.projects-section h1 {
  font-family: 'Orbitron', sans-serif;
  color: var(--color-accent);
  margin-bottom: 2rem;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.project-card {
  background-color: #2a2a2a;
  border-radius: 8px;
  padding: 1.5rem;
  flex: 1 1 300px;
  max-width: 350px;
}

.project-card img {
  width: 100%; /* Adjust based on your design preference */
  height: auto;
  border-radius: 4px;
  margin-bottom: 1rem; /* Space between image and text */
}


.project-card h2 {
  font-family: 'Orbitron', sans-serif;
  color: var(--color-secondary);
  margin-bottom: 1rem;
}

.project-card p {
  margin-bottom: 1rem;
  color: var(--color-highlight);
}

.btn-accent {
  color: var(--color-dark);
  background-color: var(--color-accent);
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
  transition: 0.3s;
}

.btn-accent:hover {
  background-color: var(--color-secondary);
}

/* Style for select dropdown */
select {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
  border: 2px solid var(--color-accent);
  outline: none;
  margin-bottom: 2rem;
  transition: background-color 0.3s, border-color 0.3s;
}

select:hover {
  background-color: #222;
  border-color: var(--color-secondary);
}

select:focus {
  border-color: var(--color-accent);
  box-shadow: 0 0 8px 0 var(--color-accent);
}

/* Style for options inside select */
option {
  background-color: #2a2a2a; /* Dark background for options */
  color: var(--color-highlight); /* Light text for options */
}
