.profile-section {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 4rem 2rem;
  background-color: var(--color-dark);
  overflow: hidden;
}

/* Stronger radial glow behind entire section */
.profile-section::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle at center,
    var(--color-accent) 15%,
    transparent 70%
  );
  opacity: 0.08;
  transform: rotate(25deg);
  z-index: 0;
}

/* Angled shape on the right side */
.profile-section::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 60vw;
  height: 100%;
  background-color: var(--color-secondary);
  transform: skewX(-15deg);
  transform-origin: top right;
  opacity: 0.05;
  z-index: 1;
}

.profile-content,
.profile-image {
  z-index: 2;
}

/* Center text for that hero look */
.profile-content {
  max-width: 550px;
  margin-bottom: 2rem;
  text-align: center;
}

/* Glitch heading (h1) */
.profile-content h1 {
  font-family: 'Orbitron', sans-serif;
  font-size: 3.2rem;
  margin-bottom: 0.5rem;
  color: var(--color-accent);
  text-transform: uppercase;
  position: relative;
  animation: glitch 2s infinite;
}

.profile-content h2 {
  font-family: 'Orbitron', sans-serif;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--color-secondary);
}

/* Paragraph with fade-in animation */
.profile-content p {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: var(--color-highlight);
  margin: 0 auto;
  max-width: 500px;
  margin-bottom: 1.5rem;
  opacity: 0; /* start hidden */
  animation: fadeInUp 1.5s ease-in-out forwards;
  animation-delay: 0.5s; /* optional delay for a staggered effect */
}

/* Profile Image Wrapper (bigger) */
.profile-image {
  position: relative;
  width: 350px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Blurred background behind the image */
.profile-image .image-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../assets/profile.jpg') center center / cover no-repeat;
  /* Darken the background a bit */
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: darken;
  filter: blur(10px);
  z-index: 0;
}

/* Square profile photo (foreground), bigger & no movement */
.foreground-image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  border: 3px solid var(--color-accent);
  box-shadow: 0 0 25px var(--color-accent);
}

/* Remove these animations if you don't want the image to move at all
   (Already removed from .foreground-image)
@keyframes float {
  ...
}

@keyframes neonPulse {
  ...
}
*/

/* FadeInUp for the paragraph text */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Glitch text animation */
@keyframes glitch {
  0% {
    text-shadow: 0.05em 0 0 var(--color-accent),
                 -0.025em -0.025em 0 var(--color-secondary);
  }
  14% {
    text-shadow: 0.05em 0 0 var(--color-secondary),
                 -0.025em -0.025em 0 var(--color-accent);
  }
  28% {
    text-shadow: 0.05em 0 0 var(--color-accent),
                 -0.025em -0.025em 0 var(--color-secondary);
  }
  70% {
    text-shadow: 0.05em 0 0 var(--color-secondary),
                 -0.025em -0.025em 0 var(--color-accent);
  }
  85% {
    text-shadow: 0.1em 0 0 var(--color-accent),
                 -0.1em -0.1em 0 var(--color-secondary);
  }
  100% {
    text-shadow: 0.05em 0 0 var(--color-accent),
                 -0.025em -0.025em 0 var(--color-secondary);
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .profile-section {
    flex-direction: column;
    text-align: center;
  }

  .profile-content {
    margin-bottom: 2rem;
  }

  /* Smaller size on mobile if desired */
  .profile-image {
    width: 250px;
    height: 250px;
  }

  .profile-content h1 {
    font-size: 2rem;
  }
  .profile-content h2 {
    font-size: 1.2rem;
  }
}
