/* :root {
  --color-dark: #1f1f1f;
  --color-accent: #00ffcc;
  --color-secondary: #ff5e5e;
  --color-highlight: #f1f1f1;
} */


:root {
  --color-dark: #000000;       /* or #121212, etc. */
  --color-accent: #00FFCC;     /* bright neon teal */
  --color-secondary: #FF5E5E;  /* electric red/pink */
  --color-highlight: #FFFFFF;  /* or #F1F1F1 */
}

/* Global resets */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--color-dark);
  color: var(--color-highlight);
  font-family: 'Roboto', sans-serif;
}

/* Headings in Orbitron */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Orbitron', sans-serif;
}
