.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--color-dark);
}

.logo {
  font-family: 'Orbitron', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--color-accent);
}

nav ul {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap; /* Allow nav items to wrap in smaller screens */
}

nav ul li a {
  color: var(--color-highlight);
  text-decoration: none;
  font-family: 'Orbitron', sans-serif;
  transition: color 0.3s;
}

nav ul li a:hover {
  color: var(--color-accent);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header-container {
      flex-direction: column;
      align-items: center;
      padding: 1rem;
  }

  .logo {
      margin-bottom: 1rem; /* Space between logo and navigation */
  }

  nav ul {
      flex-direction: column;
      width: 100%; /* Full width for smaller screens */
  }

  nav ul li {
      width: 100%; /* Each link takes full width of the nav */
  }

  nav ul li a {
      display: block; /* Makes the link fill the li */
      padding: 0.5rem; /* Larger tap targets for touch devices */
      text-align: center; /* Center align text */
  }
}
